import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
// import logo from "src/images/kalbe-logo.png"

import "../css/custom-css/custom-products-services.css"
import "../css/custom-css/custom-partnership.css"

import Layout from "../components/custom-components/Layout"
import BannerHeader from "../components/custom-components/BannerHeader"

// import bg from "../images/products-and-services/header-cmo.webp"
import bg from "../images/products-and-services/header-partnership.jpg"
import Seo from "../components/seo"

// export const query = graphql`
//   {
//     image1: contentfulServices(title: { eq: "cmo-1" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     image2: contentfulServices(title: { eq: "cmo-2" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     image3: contentfulServices(title: { eq: "cmo-3" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//   }
// `
export const query = graphql`
  query PartnershipQuery {
    allContentfulPartnership(sort: { fields: title, order: ASC }) {
      nodes {
        title
        link
        logo {
          gatsbyImageData
        }
      }
    }
  }
`

const OurPartners = ({ data }) => {
  console.log(data)

  data.allContentfulPartnership.nodes.sort((a, b) => {
    return a.title.localeCompare(b.title)
  })

  return (
    <Layout>
      <Seo title="Partnership" />

      <BannerHeader background={bg} title="Partnership" />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <h5 className="mb-4">Our Partners</h5>
            <div className="partnership-grid">
              {data.allContentfulPartnership.nodes.map(item => (
                <div className="round-partnership">
                  <a href={item.link} target="_blank">
                    <div className="col text-center">
                      <GatsbyImage
                        image={item.logo.gatsbyImageData}
                      ></GatsbyImage>
                      <h6>{item.title}</h6>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OurPartners
